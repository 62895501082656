.input-text-field-playerDataPage-styles {
	display: flex;
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	background-color: white;
	align-items: center;
	input {
		width: 170px;
	}
	svg {
		margin-top:16px;
		font-size: 28px;
	}
}
.spinner-playerDataPage {
	width: 100%;
	display:flex;
	justify-content: center;
	margin-top: 10px;
	p {
		color: white;
	}
	.spinner {
		display: flex;
		justify-content: center;
	}
}
