.mini_event_outer_container {
	margin: 10px 0px;
	max-width: 400px;
	@media screen and (max-width: 500px) {
		display: flex;
		width:100%;
		justify-content: center;
	}
	.mini_event {
		border-radius: 10px;
		margin: 0px 10px;
		min-height: 215px;
		background-color: #382964;
		@media screen and (max-width: 500px) {
			margin: 0px;
			width: 95vw;
		}

		.mini_event_header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0px 10px;
			.mini_event_title {
				color: white;
				font-size: 18px;
			}
			.mini_event_icons {
				display: flex;
				align-items: center;
				justify-content: center;
				.mini_event_icons_info {
					.info-icon-button {
						font-size: 18px;
					}
					.info-icon-button {
						margin: 0px ;
					}
				}
				.mini_event_icons_tournament {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 28px;
					width: 28px;
					a {
						display: flex;
					}
					img {

						height:20px;
					}
				}
			}
		}
		.mini_event_content_root {
			display: flex;
			margin: 1px;
			.mini_event_content {
				width: 500px;
				.icon {
					color: white;
					margin: 0px 10px;
				}
				.text {
					p {
						color: white;
						margin: 0px 0px;
						width: max-content;
						max-width: 240px;
						@media screen and (max-width: 768px) {
							max-width: 68vw;
						}
					}

				}
				.mini_event_data {
					margin: 5px 0px;
					display: flex;
					align-items: center;
				}
			}
			.mini_event_avatar {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				.MuiAvatar-root {
					height:70px;
					width: 70px;
				}
				.MuiAvatar-colorDefault {
					background-color: unset;
				}
				svg {
					height:50px;
					width: 50px;
					opacity: 0.2;
				}
				img {
					height:50px;
					opacity: 0.4 ;
				}
				.suedtirol-icon {
					height:55px;
					opacity: 0.8 !important;

				}
			}
		}
	}
}

