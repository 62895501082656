.authDialogforUploadDoc {
	background-color: black;
	display: flex;
	justify-content: flex-end;
	padding-right: 10px;
	.button_popup_menu_auth {
		height: 100%;
	}

	display:flex;
	align-items: center;
	.uploadIconDialog {
		color: white;
	}
}
.upload-data-for-player-root {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
	@media (min-width: 900px) {
		width: 400px;
	}
}
.input-upload-file-dialog {
	margin: 20px 0px;
}
.sende_notifikation-root-container {

	.sende_notifikation {
		margin-left: 16px;
		display: flex;
	}
	.sende-notification-settings {

	}
	.MuiGrid-container {
		flex-wrap: nowrap;
	}
	.picker-of-data {
		margin: 20px;
	}
	.email-for-notification {
		margin: 0px;
		margin-left: 30px;

		p {
			margin: 0px;
		}
	}
	.time-to-send-mail-radio-buttons {
		margin-left: 30px;
		.radioButton {
			svg {
				font-size: 15px;
			}
		}
	}

}
.upload-file-buttons-root {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
	.MuiButton-label {
		font-size: 18px !important;
	}
}

.disable-text-color {
	color: rgba(0, 0, 0, 0.38);
}
