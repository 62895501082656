.filter-container-events {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	.filter-options {
		display: flex;
		justify-content: center;
		border-radius: 10px;
		background-color: rgba(76, 60, 142, 0.7);
		min-height: 100px;
		width: 90vw;
		margin-bottom: 10px;
		@media screen and (min-width: 1170px) {
			width: 1180px;
		}
		@media screen and (max-width: 1170px) {
			width: calc(100% - 20px);
		}
		@media screen and (max-width: 960px) {
			width: 780px;
		}
		@media screen and (max-width: 780px) {
			width: calc(100% - 20px);
		}
		@media screen and (max-width: 580px) {
			width: 95vw;
		}
		.filter {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
		}

		.MuiFormControl-root {
			height: 27px;
			width: 260px;
			justify-content: center;
		}
		.date-container-root {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			.date_container {
				width: auto;
				display: flex;
				height: 25px;
				border-bottom: 1px solid white;
				justify-content: center;
				align-items: center;
				margin: 0px 10px;
				padding-top: 20px;
				padding-bottom: 10px;
				margin-bottom: 20px;

				svg {
					margin-bottom: 8px;
					color: white;
				}
				//Abstand
				.MuiInput-formControl {
					padding-top: 25px !important;
					margin-top: 0px !important;
				}
				.MuiFormLabel-root.Mui-error {
					position: relative;
					top: 33px;
				}
				//only for safari
				@media not all and (min-resolution: 0.001dpcm) {
					@supports (-webkit-appearance: none) {
						.MuiFormLabel-root.Mui-error {
							position: relative;
							top: 20px;
						}
						.MuiFormHelperText-root.Mui-error {
							position: relative;
							top: 12px;
						}
						.MuiInput-underline.Mui-error::after {
							display: none;
						}
					}
				}
				//Farbe Text Datum
				.MuiInputBase-input {
					color: white !important;
				}
				//Farbe Startdatum Enddatum
				.MuiFormLabel-root {
					color: white !important;
					padding-bottom: 4px;
				}
				.MuiFormControl-marginNormal {
					margin-top: 0px !important;
				}
				.MuiInput-underline:before {
					border-bottom: none;
				}
			}
		}
	}
}
// .MuiCheckbox-colorSecondary.Mui-checked {
// 	color: rgba(76,60, 142,0.7) !important;
// }

.filter_category {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 20px;
	flex-wrap: wrap;

	.MuiInputBase-root {
		color: white;
	}
	.MuiSelect-icon {
		color: white;
	}
}
.event-container-root {
	display: flex;
	justify-content: center;
	width: 100%;

	.event-container {
		max-width: 1300px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.event_not_found {
			width: 100%;
			margin-top: 20px;
			background-color: rgba(76, 60, 142, 0.7);
			display: flex;
			justify-content: center;
			color: white;
			margin-bottom: 50px;
			border-radius: 3px;
		}
		.event_not_found_width {
			border-radius: 10px;
			width: auto;
			padding: 10px;
		}
	}
}
.eventsDownload-container {
	display: flex;
	justify-content: center;
	.eventsDownload-button{
		color: white;
	}
}

.MuiGrid-justify-xs-space-around {
	width: 260px !important;
	border-radius: 5px;
	padding: 2px;
	@media screen and (max-width: 800px) {
		width: 200px !important;
	}
}

.verein_filter {
	max-width: 60vh;

	.MuiButton-text {
		padding: 0px;
	}
	.MuiButton-root {
		min-width: 30px;
		background-color: none;
	}
}
