@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

.vereinsite {
	color: white;
	background-color: black;
	width: 100%;

	.outercentercontainer {
		display: flex;
		justify-content: center;

		.start-badminton-ueberetsch-container {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.outercontainer {
				display: flex;
				justify-content: center;
				align-items: center;

				.start-badminton-ueberetsch {
					border-radius: 7px;

					width: 1440px;
					@media screen and (max-width: 1477px) {
						// width: 95vw;
						width: 938px;
					}

					@media screen and (max-width: 1186px) {
						// width: 95vw;
						width: 938px;
						// height: 37.9vw;
					}

					@media screen and (max-width: 974px) {
						width: 90vw;
					}

					@media screen and (max-width: 400px) {
						width: 90vw;
						height: 120px;
					}
					display: flex;
					justify-content: center;
					align-items: center;
					background-position: center;
					height: 35vh;
					margin: 16px;

				}
			}
		}

		.vereintitelweiß {
			@media screen and (max-width: 400px) {
				padding: 8px;
			}

			color: black;
			height: auto;
			width: auto;
			padding: 16px;
			font-size: 72px;
		}

		.trainer-verein-gruppen-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			font-size: 36px;

			@media screen and (min-width: 997px) {
				width: 974px;
			}

			@media screen and (max-width: 1940px) {
				width: 100%;
			}


			.trainer {
				border-radius: 7px;
				width: 455px;
				height: 325px;
				background-size: cover;
				background-repeat: no-repeat;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 16px;

				@media screen and (max-width: 500px) {
					width: 90vw;
					height: 210px;
					margin-bottom: 10px;
				}

				.trainertitelweiß {
					color: black;
					height: auto;
					width: auto;
					padding: 16px;
				}
			}


			.gruppen {
				.slide-show-container {
					border-radius: 7px;
				}

				width: 455px;
				height: 325px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 16px;
				font-weight: bold;

				@media screen and (max-width: 500px) {
					width: 90vw;
					height: 210px;
					margin-bottom: 20px;
				}
			}
		}
	}
}

.home_events {
	width: 30vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.kacheln_container {
	width: 100%;
	display: flex;
	justify-content: center;

	@media screen and (max-width: 1940px) {
		flex-wrap: wrap;
	}

	@media screen and (max-width: 1199px) {
		flex-flow: wrap;
	}

	@media screen and (min-width: 1500px) {
		justify-content: center;
	}

}



.latestEvent-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.iframe-container-root {
	display: flex;
	width: 100%;
	justify-content: center;

	.iframe-container iframe {
		height: 100%;
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

}

.iframe-root {
	@media screen and (min-width: 2400px) {
		display: flex;
		justify-content: center;
	}
}

.iframe-container {
	max-width: 1900px;

	@media screen and (min-width: 2400px) {
		width: 1900px;
	}

	margin: 20px 10vw;

	@media screen and (max-width: 700px) {
		margin: 20px 2vw;
	}
}

iframe {

	.powered-by {
		display: none;
	}
}

.socialwall_header {
	display: none !important;
}

.socialWall-title-root {
	margin-top: 50px;
	width: 100%;

	.socialWall-title {
		font-family: 'Gloria Hallelujah', cursive;
		justify-content: center;
		display: flex;
		font-size: 10rem;

		@media (max-width: 1100px) {
			font-size: 10ex;
		}

		@media (max-width: 460px) {
			font-size: 15vw;
		}

		p {
			margin: 0px;
		}

	}

	.socialWall-hashtagtitle {
		p {
			margin: 0px;
			margin-top: -60px;
			font-size: 5rem;

			@media (max-width: 1100px) {
				margin-top: -30px;
				font-size: 5ex;
			}

			@media (max-width: 460px) {
				margin-top: -10px;
				font-size: 8vw;
			}

			font-family: 'Amatic SC',
			cursive;
		}

		justify-content: center;
		display: flex;
	}

	.socialWall-hashtag-infotext {
		p {

			display: none;
		}

		justify-content: center;
		display: flex;

	}
}
