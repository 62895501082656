.signIn-outer-container{
	margin-top: 0px;
	display: flex;
	align-items: center;
	padding-bottom: 50px;
	.MuiButton-label {
		font-size: 0.875rem !important;
	}
	hr {
		background-color: #b8b8b873;
	}
	.user_help {
		color: white;
		display: flex;
		justify-content:space-between;
	}

	.MuiButton-containedPrimary {
		background-color: #382964;

	}
}
.error {
	height: 43px;
	color: red;
}
.success {
	color: forestgreen;
}

.auth-component-text-styles {
	margin: 5px 0px !important;
	@media (max-width: 768px) {
		margin: 3px 0px !important;
		font-size: 10px !important;
	}
}

.submit-button-styles-auth {
	margin: 0px 0px 20px 0px !important;
}
