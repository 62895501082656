.documents-group-root {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .documentsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	max-width: 1500px;
  }
}
.hr-text-root {
  width: 100%;
  .hr-text {
    margin: 10px;
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: white;
    text-align: center;
    height: 1.5em;
    opacity: 0.7;
    &:before {
      content: "";
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: white;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: white;
      padding: 0 0.5em;
      line-height: 1.5em;
      // this is really the only tricky part, you need to specify the background color of the container element...
      color: white;
      background-color: black;
    }
  }
}
