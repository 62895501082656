.curr-user-paper-data{
	margin: 20px 0px 0px 10px;
	width: 48%;
	height:50vh;
	@media screen and (max-width: 1000px) {
		width: 500px;
		height:350px;
	}
	@media screen and (max-width: 500px) {
		width: 95vw;
		height: 100%;
		padding: 15px 0px;
	}
	background-color: rgb(231, 228, 228) !important;

}

.input-text-field-table-styles {
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	background-color: white;
	input {
		width: 170px;
	}
}
.user-table-top-functions-styles {
	display: flex;
	background-color: white;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
}

.table-root {
	border-radius: 0px !important;
	padding: 0px 10px;
	th {
		min-width: 50px !important;
	}
	.MuiTableCell-root {
		padding: 0px;
	}
}

