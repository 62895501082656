.DocumentComponent-root {
	margin: 10px;
	border: 1px solid white;
	height: 190px;
	width: 150px;

	.ItemIcon {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		height: 100px;
	}
	hr {
		margin: 0px;
	}
	.itemName {
		color: white;
		height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		p {
			 text-overflow: ellipsis;
			 margin: 0px 8px;
			 text-align: center;
		 }
	}
}
