.documents-root-styles-container {
	min-height: 70vh;
}
.download-outer-container-root {
	background-color: white;
	max-width: 1300px;
	// height: 20px;
}
.ordnerStyles {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 1300px;
}
.inhalt-outer-container {
	display: flex;
	justify-content: center;
}
.ordner-data {
	padding-left: 10px;
}
.odner-style-div {
	margin-left: 10px;
}
.files-style-div {
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
}

.documents-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 83vh;
}

.titel {
	font-size: 24px;
	font-weight: bold;
	color: white;
}

.text{
	font-size: 14 px;
	color: black !important;
}


.öffentlich {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.corona {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.spielerspezifisch {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.buttontext{
	color: black;
	font-size: 16px;
	.MuiButton-label{
		font-size: 14px !important;
}
}

