.bb-feedback-button {
  right: 16px;
  bottom: 16px;
}

.scrollTopStyles {
	right: 16px;
	bottom: 16px;
	position: fixed;
  }


.bb-feedback-button-icon {
  height: 38px;
  width: 38px;
  border-radius: 38px;
  background-color: #382964 !important;
  border: 1px solid grey !important;
}

.bb-feedback-button svg {
  width: 17px !important;
  height: 17px !important;
}

.bb-feedback-button .bb-logo-arrowdown {
    top: 11px;
  left: 11px;
}

.socialwall_header {
  display: none;
}
a {
  text-decoration: none;
}

.background-container {
  background-color: black;
  margin-top: 48px;
  flex: 1 1 0;
  display: flex;
  align-items: space-between;
  align-content: space-between;
  flex-direction: column;
  min-height: 80vh;
}
.MuiFab-secondary {
  background-color: #382964 !important;
  border: 1px solid grey !important;
}

.paper-app-style {
  background-color: black !important;
}

.spaceGame {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: black;
}

.display_none {
  visibility: hidden;
}
.MuiButton-contained {
  text-transform: unset !important;
}
.MuiPickersDay-daySelected {
  background-color: #382964 !important;
}
.MuiPickersToolbar-toolbar {
  background-color: #382964 !important;
}
* {
  text-transform: none !important;
}

.gameStyles {
  padding: 0;
  margin: 0;
  font-family: "PT Mono", serif;
  color: #ffffff;
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: black;

  canvas {
    display: block;
    background-color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .current-score {
    left: 20px;
  }
  .top-score {
    right: 20px;
  }
  .score {
    display: block;
    position: absolute;
    top: 15px;
    z-index: 1;
    font-size: 20px;
  }
  .controls {
    display: block;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    font-size: 11px;
    text-align: center;
    line-height: 1.6;
  }
  .endgame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
    z-index: 1;
    text-align: center;
  }
  button {
    border: 4px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px;
    margin: 10px;
    font-family: "PT Mono", serif;
    cursor: pointer;
  }
  button:hover {
    background-color: #ffffff;
    color: #000000;
  }
}
