.player-config-outer-root {
	.MuiTable-root {

		background-color: black;
	}
}
.player-config-paper {
	width: 100%;
	height: 500px;

}
.button-player-config-outer-container {
	@media screen and (min-width: 600px) {
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
	}
}
.player-config-name {
	margin-top: 10px;
	color: #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin-left: 10px;
	}
}
.buttons-player-root {
	padding: 20px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	.buttons-player-settings {
		@media (max-width: 600px) {

			width: 100%;
		}
		margin: 5px;
		.MuiButton-label {
			font-size: 18px !important;
		}
	}
}
