.white {
	color: white;
}
.infoicon-player {
	align-items: center;
	justify-content: center;
	display: flex;
	opacity: .7;
}

.customPlayerData-spinner {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 30px;
}

.customPlayer-outer-styles {
	.MuiPaper-root {
		background-color: black;
	}
	.MuiSvgIcon-root {
		color: white;
	}

	.MuiAccordionSummary-root {
		border-bottom: white solid 1px;
	}
	.MuiAccordionDetails-root {
		@media screen and (max-width: 920px){
			display: flex;
			justify-content: center;
		}
	}
	.download_container{
		min-width: 200px;
		background-color: white;
		border-radius: 5px;
		margin: 10px;
		border: 1px solid #ccc;
		@media screen and (max-width: 920px){
			min-width: auto;
			width: 70vw;
		}
		.file {
			display: flex;
			justify-content: center;
			padding: 10px 0px;
		}
		.footer-file {
			@media screen and (max-width: 920px){
				p {
					word-break: break-all;
				}
			}
			p {
				margin: 0px;
			}
			background-color: rgba(150, 135, 192, 0.7);
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;
			display: flex;
			justify-content: center;
			padding: 4px 0px;
			height: 25px;
			padding: 5px;
			color: purple;
			@media screen and (max-width: 920px){
				height: auto;
			}
		}
	}

}
.noPlayerRef {
	display:flex;
	justify-content: center;
	margin-top: 20px;
	color: white;
	a {
		color: white;
	}
}
