.upload-doc-style-outer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background-color: black;
	@media screen and(max-width: 920px) {
		justify-content: center;
	}
}
.null-links-outer {
	display: flex;
	@media screen and(max-width: 920px) {
		display: inline-block;
	}
}
.no-document {
	margin-right: 10px;
	display: flex;
	align-items: center;
}
.uploadIcon {
	margin: 10px;
	font-size: 10px;
}
.tableCellStyles {
	color: #ccc !important;
	p {
		color: #ccc !important;
	}
	svg {
		font-size: 20px;
	}
}
.stickyheadtable-root {
	width: 100%;
	.MuiPaper-root {
		width: 100%;
	}
	.MuiTypography-root {
		width: 100%;
	}
	p {
		margin: 0px;
	}
	@media screen and (max-width: 920px){
		p {
			word-break: break-all;
		}
	}
}
.player-infos {
	display: flex;
	align-items: center;

	.player-name {
		color: white;
		margin: 10px;
	}
}

.input-upload-file {
	display: none;
}

