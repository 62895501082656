.cellStyles {
  background-color: fade(pink, 0.1);
}
// .cStyles:hover {
//   background-color: fade(#66bb6a, 0.15);
// }
// .cStyles:focus {
//   background-color: fade(#66bb6a, 0.2);
// }

// .headerCellStyles {
//   background-color: fade(#66bb6a, 0.1);
// }
// .headerCellStyles:hover {
//   background-color: fade(#66bb6a, 0.1);
// }
// .headerCellStyles:focus {
//   background-color: fade(#66bb6a, 0.1);
// }
.groupingIconStyles {
	padding-left: 20;
	vertical-align: middle;
}
.makeStyles-timeScaleContainer-50 {
	width: 60px !important;
}

.Label-label-57 {
	padding-right: 2px !important;
}
.playerAnwesendListStyles {
	justify-content: center;
	display: flex;
}
.calendarStyles {
	height:80vh;

	.MuiToolbar-root {
		min-height: 50px !important;
	}

	.LayoutBase-notesEditor-555{
		display: none;
	}
}
