.Mannschaftsspiel {
	background-color: #382964;
}

.Internationales {
	background-color: #382964;
}

.Italienmeisterschaft {
	background-color: #382964;
}

.Landesmeisterschaft {
	background-color: #382964;
}

.Nationales {
	background-color: #382964;
}

.VSS {
	background-color: #382964;
}

.Kurse {
	background-color: #382964;
}
.Over {
	background-color: #382964;
}
