.event-description {
	.MuiDialog-paperWidthSm {
		max-width: 60vw !important;

		@media screen and (max-width: 800px){
			max-width: none !important;
		}
	}
}

.information {
	display: flex;
	justify-content: center;
	.info-icon-button {
		color: white;
		margin: 10px;
		padding: 0px;
		font-size: 25px;
	}

	.MuiButton-root {
		min-width: auto;
	}

	@media screen and (max-width: 700px){
		.button {
			bottom: auto;
		}
	}
}
#customized-dialog-title {
	min-width: 30vw;
}
