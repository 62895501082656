.footer {
	color: white;
	width: 100%;
	background-color: #382964;


	.content_container_footer {
		padding-left:20px;
		padding-top: 10px;
		padding-bottom: 10px;
		display: flex;
		@media screen and (max-width: 500px){
			display: inline-block;
			width: 100%;
			padding: 0px;
		}
		.left_content_container_footer{
			display: flex;
			justify-content: space-between;
			flex: 1 1 0;
			margin: 0px 50px;
			.footer-point{
				text-decoration: none;
				color: white;

				:hover {
					text-decoration: underline;
				}
			}
		}
		.footer_logo_Verein{
			width: 80px;
			justify-content: center;
			display: flex;
			align-items: center;
			padding-right: 20px;
			border-right: 1px solid grey;
			img {
				width: 50px;
			}
		}
	}

	.followus-container {
		padding: 20px;
		display: flex;
		border-left: 1px solid grey;
		align-items:center ;
		justify-content: center;
		color: white;
		@media screen and (max-width: 500px){
			padding-left:0px ;
			border-left: none;
			border-top: 1px solid grey;
		}

		.company-logo {
			display: flex;
			align-items: center;
			margin: 10px;

		}
	}

	.sponsor-styles{
		background-color: white;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@media screen and (max-width: 795px){
			justify-content: center;
		}
		align-items: center;
		padding-right: 50px;
		padding-left: 50px;


		img {
			max-width: 100px;
			max-height: 60px;
			margin: 10px 10px;
			@media screen and (max-width: 500px){
				margin: 10px;
				max-width: 60px;
			}
		}
	}
}
.hyperlink{
	text-decoration: none;
	color: white;
}

.sponsored-by-root {
	background-color: white;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-bottom: 3px;
	.sponsored-by-content {
		padding: 10px 0px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 35px;
		img {
			width: 100px;
		}

		p {
			margin-left: 5px;
			font-size: 10px;
			font-weight: bold;
			color: black;
		}
	}
}
