html {
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-size: 100%;
  background-repeat: repeat-y;
  background-position: 0px 50px;
  width: 100%;

}

/* @media screen and (max-width: 1100px){ */
body {
    background-image: none;
    background-color: black;
    scrollbar-width: none; /* for Firefox */

}
/* } */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}
