.signup-outer-container {
	display: flex;
	align-items: center;
	.MuiButton-label {
		font-size: 0.875rem !important;
	}
	.makeStyles-paper-13 {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.MuiButton-containedPrimary {
		background-color: #382964;
	}
}
.error {
	height: 43px;
	color: red;
}
.success {
	color: forestgreen;
}

.signUp-checkbox {
	.Mui-checked {
		color: #382964 !important;
	}
}
