.slide-show-outer-container{
	display:flex;
	flex-direction: column;
	justify-content: center;
	height: 70vh;
	margin-top: 150px;
	@media screen and (max-width: 1100px){
		height: 85vh;
		margin-top: 50px;
	}
}

.slide-show-container_components {
	justify-content: center;
	align-items: center;
	padding: 4vh 4vw;
	@media screen and (max-width: 1200px){
		padding:0px 10px;
	}
	@media screen and (min-width: 1600px){
		padding: 10px;
	}
	.slideshow_buttons_components {
		display: flex;
		justify-content: center;
		align-items: center;
		.fade {
			justify-content: center;
			align-items: center;
			display: flex;
			img {
				max-height: 65vh;
				max-width: 90vw;
				// margin: calc((100vh -  576px)/2) 0px;
				@media screen and (max-width: 1350px){
					max-height: 700px;
					max-width: 500px;

				}
				@media screen and (max-width: 1200px){
					max-height: 600px;
					max-width: 500px;

				}
				@media screen and (max-width: 1000px){
					max-height: 400px;
					max-width: none;

				}
				@media screen and (max-width: 850px){
					max-height: 320px;
					max-width: none;

				}
				@media screen and (max-width: 800px){
					max-height: 90%;
					max-width: 50vh;

				}
				@media screen and (max-width: 700px){
					max-width: 90vw;
				}
				@media screen and (max-width: 500px){
					max-width: 90vw;
				}
			}
		}
		.prev, .next {
			margin: 4px;
			display: flex;
			justify-content: center;
			align-items: center;

			.round {
				width: 30px;
				height: 30px;
				border-radius: 30px;
				background-color: rgba(201, 200, 200, 0.8);
				display: flex;
				justify-content: center;
				align-items: center;
				@media screen and (max-width: 700px){
					display: none;
				}
			}
		}
	}
	.dot-container {
		display: flex;
		justify-content: center;
		position: relative;
		.dot {
			width: 20px;
			height: 20px;
			border-radius: 10px;
			background-color: rgba(97, 97, 97, 0.7);
			margin: 8px;
			@media screen and (max-width: 500px){
				width: 10px;
				height: 10px;
			}
		}

		.curr {
			background-color: rgba(255, 255, 255, 0.8);
		}
	}
}

// .fade {
// 	-webkit-animation-name: fade;
// 	-webkit-animation-duration: 2.5s;
// 	animation-name: fade;
// 	animation-duration: 2.5s;
//   }

//   @-webkit-keyframes fade {
// 	from {opacity: .4}
// 	to {opacity: 1}
//   }

//   @keyframes fade {
// 	from {opacity: .4}
// 	to {opacity: 1}
//   }
