.user-config-user-data {
	padding: 10px 10px 0px 10px;
}
.user-config-root {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.MuiInputBase-input {
		width: 200px;
	}
}
.transferlist-paper{
	display: flex;
	align-items:center;
}
.SpinnerUserConfig {

	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0px 0px 10px;
	width: 48%;
	height: 50vh;
	background-color: #e7e4e4 !important;
}
.SpinnerPlayerTransferList {
	color: black !important;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
