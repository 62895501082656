.button_popup_menu_auth {
		font-size: 20px ;

}
.dialogTitle-mobile-menu {
	.MuiDialogTitle-root {
		position: absolute;
		right: 20px;
		display:flex;
		justify-content: flex-end;
		margin: 0px;
		padding: 12px 0px 0px 0px;
	}
}

.dialogContainer-auth-popup {
	.dialogContainer-auth-popup-inner-root {
		display: flex;
		justify-content: center;
		height: 100%;
	}

	.MuiDialog-paperWidthSm {
	}
}
