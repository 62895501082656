.playerPresentRoot {
	.MuiList-root {
		display:flex;
		flex-direction: column;
		justify-content:space-between;
	}
	.MuiListItem-root {
		display:flex;
		justify-content:space-between;
	}
}
