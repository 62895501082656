.addNewPlayer-Button-root {
	background-color: white !important;
	border-radius: 5px;
	margin: 5px !important;
}

.addUserInputComponents-root-styles {
	margin: 0px 20px;
	max-width: 600px;
	@media screen and(max-width: 800px) {
		margin-top: 25px;
	}
	.MuiButton-root {
		background-color: #382964;
		text-transform: unset;

	}
	.component-text-styles {
		font-size: 20px;
		margin: 0px;
	}
	.alertMsg-player-component {
		@media screen and (min-width: 768px) {
			min-height: 20px;

		}
		.allertcontainer {
			background-color: white;
		}
	}
}
